var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.disabled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success px-5",
                      attrs: { type: "button" },
                      on: { click: _vm.submeter }
                    },
                    [_vm._v(_vm._s(_vm.model.id == 0 ? "Criar" : "Alterar"))]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      _vm.$router.push({ name: "cadastros.procedimento.lista" })
                    }
                  }
                },
                [_vm._v("Voltar")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-selectsearch", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            ref: "tabelaDominio",
            attrs: {
              label: "Tabela",
              name: "tabelaDominioId",
              disabled: _vm.disabled,
              "data-vv-as": "Tabela",
              modelErrors: _vm.errors,
              cols: 3,
              searchFunction: _vm.pesquisarTabelaDominio,
              "min-length": 2
            },
            on: { select: _vm.addTabelaDominio },
            model: {
              value: _vm.model.tabelaDominioId,
              callback: function($$v) {
                _vm.$set(_vm.model, "tabelaDominioId", $$v)
              },
              expression: "model.tabelaDominioId"
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            attrs: {
              name: "codigo",
              disabled: _vm.disabled,
              "data-vv-as": "Código",
              label: "Código",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.codigo,
              callback: function($$v) {
                _vm.$set(_vm.model, "codigo", $$v)
              },
              expression: "model.codigo"
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            attrs: {
              name: "descricao",
              disabled: _vm.disabled,
              "data-vv-as": "Descricao",
              label: "Descrição",
              modelErrors: _vm.errors,
              cols: 6
            },
            model: {
              value: _vm.model.descricao,
              callback: function($$v) {
                _vm.$set(_vm.model, "descricao", $$v)
              },
              expression: "model.descricao"
            }
          }),
          _c("f-switch", {
            attrs: {
              name: "ativo",
              disabled: _vm.disabled,
              cols: 12,
              label: _vm.model.ativo ? "Ativo" : "Inativo"
            },
            model: {
              value: _vm.model.ativo,
              callback: function($$v) {
                _vm.$set(_vm.model, "ativo", $$v)
              },
              expression: "model.ativo"
            }
          }),
          !_vm.disabled
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-6 d-flex justify-content-start" },
                  [_c("h4", { staticClass: "mt-3" }, [_vm._v("Convênios")])]
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-6 d-flex justify-content-end" },
                  [
                    _c(
                      "label",
                      { staticClass: "d-inline-flex align-items-center" },
                      [
                        _vm._v(" Filtrar: "),
                        _c("b-form-input", {
                          staticClass: "form-control form-control-sm ms-2",
                          attrs: { type: "search" },
                          on: { input: _vm.filtrarConvenios },
                          model: {
                            value: _vm.convenios.filter.filtrar,
                            callback: function($$v) {
                              _vm.$set(_vm.convenios.filter, "filtrar", $$v)
                            },
                            expression: "convenios.filter.filtrar"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("ui-table", {
                  attrs: {
                    id: "convenio-convenio",
                    colunas: _vm.convenios.colunas,
                    items: _vm.conveniosFiltrados,
                    loading: _vm.convenios.loading,
                    "force-script-ordenacao": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function(row) {
                          return [
                            !_vm.disabled
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-danger",
                                      small: "",
                                      title: "remover"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.removerConvenio(row.data)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "uil uil-multiply icon-size"
                                    })
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      !_vm.disabled
                        ? {
                            key: "hactions",
                            fn: function() {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-success",
                                      small: "",
                                      title: "adicionar"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.adicionarConvenio()
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "uil uil-plus" })]
                                )
                              ]
                            },
                            proxy: true
                          }
                        : null,
                      {
                        key: "renderCell(nome)",
                        fn: function(data) {
                          return [
                            data.item.id > 0
                              ? _c("div", [_vm._v(_vm._s(data.value))])
                              : _c("f-selectsearch", {
                                  attrs: {
                                    name: "selconv",
                                    placeholder:
                                      "Digite para pesquisar o convênio",
                                    labelField: "nome",
                                    searchFunction: _vm.pesquisarConvenio
                                  },
                                  on: { select: _vm.selecionarConvenio }
                                })
                          ]
                        }
                      },
                      {
                        key: "renderCell(valorProcedimento)",
                        fn: function(data) {
                          return [
                            _vm.disabled
                              ? _c("div", [_vm._v(_vm._s(data.value))])
                              : _c("money", {
                                  staticClass: "text-end form-control",
                                  attrs: {
                                    placeholder:
                                      "Valor do procedimento no convênio"
                                  },
                                  model: {
                                    value: data.item.valorProcedimento,
                                    callback: function($$v) {
                                      _vm.$set(
                                        data.item,
                                        "valorProcedimento",
                                        $$v
                                      )
                                    },
                                    expression: "data.item.valorProcedimento"
                                  }
                                })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }