import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get("procedimento/listar?" + param2query(params), cancelToken);
};

export const getProcedimento = (id) => {
  return api.get("procedimento/" + id);
};

export const criarProcedimento = (model) => {
  return api.post("procedimento", model);
};

export const atualizarProcedimento = (model) => {
  return api.put("procedimento", model);
};

export const getAllConvenio = (params, cancelToken) => {
  return api.get('procedimento/buscar-convenios?' + param2query(params), cancelToken);
};

export const getAllTabelaDominio = (params, cancelToken) => {
  return api.get('procedimento/buscar-tabelas-dominio?' + param2query(params), cancelToken);
};

export default { getAll };
